<template>
  <a-layout>
      <a-layout-header>
        <global-header />
      </a-layout-header>
    <a-layout-content :style="{ padding: '0 50px' }">
      <slot></slot>
      <!-- <a-breadcrumb :style="{ margin: '16px 0' }">
        <a-breadcrumb-item>Home</a-breadcrumb-item>
        <a-breadcrumb-item>List</a-breadcrumb-item>
        <a-breadcrumb-item>App</a-breadcrumb-item>
      </a-breadcrumb>
      <div :style="{ background: '#fff', padding: '24px', minHeight: '380px' }">Content</div> -->
    </a-layout-content>
    <a-layout-footer :style="{ textAlign: 'center' }">
      Ant Design ©2018 Created by Ant UED<br>
      备案号：<a href="https://beian.miit.gov.cn" target="_blank">粤ICP备19068393号</a>
    </a-layout-footer>
  </a-layout>
</template>
<script>
import GlobalHeader from '@/components/GlobalHeader.vue'
export default {
  name: 'BasicLayout',
  components: {
    GlobalHeader
  }
}
</script>
<style>

.site-layout .site-layout-background {
  background: #fff;
}

[data-theme='dark'] .site-layout .site-layout-background {
  background: #141414;
}
</style>