import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue';
//import Axios from 'axios';
import 'ant-design-vue/dist/antd.css';
import './mock'
import axios from './utils/request'


var app = createApp(App)
app.config.globalProperties.$http=axios //全局配置axios

app.use(Antd).use(store).use(store).use(router).mount('#app')
