<template>
  <a-card :body-style="{ paddingBottom: 20 }" hoverable @click="showDetail">
    <template #cover>
      <div :style='{ backgroundImage:"url(" + info.cover + ")"}'></div>
    </template>
    <a-card-meta :title="info.name"></a-card-meta>
    <div>
      <ul class="list-inline">
        <li><clock-circle-outlined /> {{ pubdate }}</li>
        <li><eye-outlined /> {{ info.view }}</li>
        <li><message-outlined /> {{ info.comment }}</li>
      </ul>
    </div>
  </a-card>
</template>

<script>
import moment from 'moment'
import { defineComponent } from 'vue';
import { ClockCircleOutlined, EyeOutlined, MessageOutlined } from '@ant-design/icons-vue';
import 'moment/locale/zh-cn'
moment.locale('zh-cn')

export default defineComponent ({
  name: 'ImageCard',
  components: {
    ClockCircleOutlined,
    EyeOutlined,
    MessageOutlined
  },
  props: {
    info: {
      type: Object,
      default: () => null
    }
  },
  data() {
      return {

      }
  },
  computed: {
    pubdate() {
      return moment(this.info.pubdate).fromNow()
    }
  },
  methods: {
    showDetail () {
      console.log(this.info)
      const id = this.info.id
      this.$router.push({ name: 'midiDetail', params: { id: id } })
    }
  }
})
</script>

<style lang="less" scoped>

.ant-card-cover > div {
  width: 100%;
  height: calc(24vh);
  //background: url("http://p.qpic.cn/qqconadmin/0/292590fd5c4a4553864f8f56f8d29a92/0");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
}

.list-inline {
  padding-inline-start: 0px;
  color: #8c8c8c;

  li {
    float:left;
    list-style:none;
    margin-right: 15px;
  }

  // li:not(:first-child) {
  //   float:right;
  //   margin-left: 15px;
  // }

}
</style>
