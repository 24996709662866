<template>
  <BasicLayout>
    <a-breadcrumb :style="{ margin: '16px 0' }">
      <a-breadcrumb-item>Home</a-breadcrumb-item>
      <a-breadcrumb-item>List</a-breadcrumb-item>
      <a-breadcrumb-item>App</a-breadcrumb-item>
    </a-breadcrumb>
    <div :style="{ background: '#fff', padding: '24px', minHeight: '380px' }">
      <a-divider orientation="left" orientation-margin="0px">最热钢琴曲</a-divider>
      <a-list
        :loading="loading"
        :data-source="data"
        :grid="{ gutter: 24, xl: 4, lg: 3, md: 3, sm: 2, xs: 1 }"
        style="margin-top: 24px;"
      >
      <template #renderItem="{ item }">
        <a-list-item>
          <image-card :info="item"/>
        </a-list-item>
      </template>
      </a-list>
      <a-divider orientation="left" orientation-margin="0px">最新钢琴曲</a-divider>
      33333333333333333333333333333333333
      <a-divider orientation="left" orientation-margin="0px">演奏视频</a-divider>
      44444444444444444444444444444444444
    </div>
  </BasicLayout>
</template>

<script>
// @ is an alias to /src
import BasicLayout from '@/layouts/BasicLayout.vue'
import ImageCard from '@/components/card/ImageCard.vue'

export default {
  name: 'HomeView',
  components: {
    BasicLayout,
    ImageCard
  },
  data () {
    return {
      data: [],
      loading: true
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    getList () {
      console.log(this.$http);
      this.$http.get('/list/midi', { params: { count: 8 } }).then(res => {
        console.log('res', res)
        this.data = res.result
        this.loading = false
      })
    }
  }
}
</script>
