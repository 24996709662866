<template>
  <div :style="{ zIndex: 1, width: '100%' }">
      <div class="left">IMIDI</div>
      <div class="right">
          <avatar-dropdown :currentUser="currentUser" :menu="menu" />
      </div>
      <a-menu
        v-model:selectedKey="selectedKey"
        theme="dark"
        mode="horizontal"
        :style="{ lineHeight: '64px' }"
      >
        <a-menu-item key="1">首页</a-menu-item>
        <a-menu-item key="2">钢琴曲</a-menu-item>
        <a-menu-item key="3">视频</a-menu-item>
        <a-menu-item key="4">装饰</a-menu-item>
        <a-menu-item key="5">动态</a-menu-item>
      </a-menu>
  </div>
</template>

<script>
import AvatarDropdown from './AvatarDropdown.vue'
export default {
  components: { AvatarDropdown },
  data() {
    return {
      selectedKey: 1,
      currentUser: {
          name: '李四'
      },
      menu: true
    }
  }
}
</script>

<style>
.left {
  width: 120px;
  height: 30px;
  /* background: rgba(255, 255, 255, 0.2); */
  margin: 16px 24px 16px 0;
  float: left;
  line-height: 30px;
  font-size: 30px;
  color: #fff;
}
.right {
  margin: 16px 24px 16px 0;
  float: right;
  line-height: 30px;
  color: #fff;
}
.site-layout .site-layout-background {
  background: #fff;
}

[data-theme='dark'] .site-layout .site-layout-background {
  background: #141414;
}
</style>